export default {
  'Rascunho': 1,
  'Em análise': 2,
  'Aprovada': 3,
  'Em emissão': 4,
  'Emitida': 5,
  'Declinada': 6,
  'Erro na emissão': 7,
  'Ativa': 8,
  'Cancelada': 9,
  'Endossada': 10,
  'Requisitado': 11,
  'Em progresso': 12,
  'Pendente': 13,
  'Resolvido': 14,
  'Aguardando documentação': 15,
  'Finalizada': 16,
  'Renovada': 17,
  'Aguardando aprovação': 18,
  'Pago': 19,
  'Vencido': 20,
  'Em aberto': 21,
}
